import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import ContributePage from '../pagesComponents/contributePage';

const Contribute = ({ location }) => (
  <Layout location={location}>
    <ContributePage />
  </Layout>
);

Contribute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Contribute;
