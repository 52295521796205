import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import menuPaths from '../constants/menuPaths';
import ContentWrapper from '../components/contentWrapper';

import penImage from '../images/contribute.png';
import styles from '../styles/pagesComponents/contributePage.module.scss';

const ContributePage = ({ intl }) => {
  const language = intl.locale;
  const contactUsPath = `/${language}/${menuPaths.contactUs[language]}`;

  return (
    <ContentWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: 'menu.contribute' })}</title>
        <meta name="description" content={intl.formatMessage({ id: 'meta.description.contribute' })} />
        <meta property="og:title" content={intl.formatMessage({ id: 'menu.contribute' })} />
        <meta property="og:description" content={intl.formatMessage({ id: 'meta.description.contribute' })} />
      </Helmet>
      <div className={styles.container}>
        <img src={penImage} alt={intl.formatMessage({ id: 'contribute.altText' })} />
        <h2>
          <FormattedMessage
            id="menu.contribute"
          />
        </h2>
        <FormattedMessage
          id="contribute.text"
        />
        <FormattedMessage
          id="contribute.secondText"
        />
        <FormattedMessage
          id="contribute.thirdText"
        />
        <FormattedMessage
          id="contribute.join"
        />
        <FormattedMessage
          id="contribute.translation"
        />
        <Link to={contactUsPath} className={styles.link}>
          <FormattedMessage
            id="menu.contactUs"
          />
        </Link>
      </div>
    </ContentWrapper>
  );
};

ContributePage.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ContributePage);
